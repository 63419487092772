import * as React from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col, Button, Card, Accordion } from "react-bootstrap"
import { FaChevronDown } from "react-icons/fa"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Cta from "../components/cta"
import Banners from "../components/banners"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query Index {
      api {
        banners(where: { sections: { slug: "home" } }) {
          id
          link
          link_type {
            slug
          }
          sections {
            slug
          }
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, height: 800, quality: 75)
              }
            }
          }
          image_mobile {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, height: 350, quality: 75)
              }
            }
          }
        }
        faqs {
          question
          answer
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo title="Home" />
      <Banners data={data.api.banners} />
      <Container className="padding-top-lg padding-bottom-lg">
        <Row className="title half-padding-bottom">
          <Col>
            <h1>Vantagens do sistema fotovoltaico</h1>
          </Col>
        </Row>
        <Row>
          <Col
            className="text-center py-4 py-md-0"
            xs={12}
            md={4}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/icon_sustentabilidade.png"
              height={90}
              alt="Ícone com um globo terrestre"
              placeholder="tracedSVG"
            />
            <div>
              <h2 className="title-sm mt-3">Sustentabilidade</h2>
              <p>
                Ela não produz resíduos ou danos ambientais, ou seja, é fonte de
                energia limpa, sustentável e sem consumo de recursos naturais.
              </p>
            </div>
          </Col>
          <Col
            className="text-center py-4 py-md-0"
            xs={12}
            md={4}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/icon_retorno.png"
              height={90}
              alt="Ícone com um saco de dinheiro"
              placeholder="tracedSVG"
            />
            <div>
              <h2 className="title-sm mt-3">Retorno do investimento</h2>
              <p>
                A ideia é trocar um custo fixo e vitalício por um custo
                programado que pode variar de 3 a 5 anos.
              </p>
            </div>
          </Col>
          <Col
            className="text-center py-4 py-md-0"
            xs={12}
            md={4}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/icon_valorizacao.png"
              height={90}
              alt="Ícone com uma moeda"
              placeholder="tracedSVG"
            />
            <div>
              <h2 className="title-sm mt-3">Valorização</h2>
              <p>
                Especialistas afirmam que os imóveis podem valorizar em até 30%
                após a instalação das placas solares.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Cta />
      {/* <Container className="padding-top-lg padding-bottom-lg">
        <Row className="title half-padding-bottom">
          <Col>
            <h1>Depoimentos</h1>
          </Col>
        </Row>
        <Row className="text-center">
          <Col
            xs={12}
            md={4}
            className="py-4 py-md-0"
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/elipse.png"
              height={130}
              alt="Ícone depoimento"
              placeholder="tracedSVG"
              className="mb-4"
            />
            <p className="font-weight-600">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <h2 className="title-xs mt-3">Nome do depoimento</h2>
          </Col>
          <Col
            xs={12}
            md={4}
            className="py-4 py-md-0"
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/elipse.png"
              height={130}
              alt="Ícone depoimento"
              placeholder="tracedSVG"
              className="mb-4"
            />
            <p className="font-weight-600">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <h2 className="title-xs mt-3">Nome do depoimento</h2>
          </Col>
          <Col
            xs={12}
            md={4}
            className="py-4 py-md-0"
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <StaticImage
              src="../images/elipse.png"
              height={130}
              alt="Ícone depoimento"
              placeholder="tracedSVG"
              className="mb-4"
            />
            <p className="font-weight-600">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <h2 className="title-xs mt-3">Nome do depoimento</h2>
          </Col>
        </Row>
      </Container> */}
      <Container className="half-padding-top padding-bottom-lg">
        <Row className="title half-padding-bottom">
          <Col>
            <h1 className="mb-1">Veja se as suas dúvidas estão aqui</h1>
            <h2>E se sinta à vontade para entrar em contato conosco</h2>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={11}>
            <Accordion defaultActiveKey="0">
              {data.api.faqs.map((faq, index) => {
                return (
                  <Card key={index}>
                    <Accordion.Toggle as={Card.Header} eventKey={String(index)}>
                      {faq.question}
                      <FaChevronDown className="float-right align-self-center" />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={String(index)}>
                      <Card.Body
                        dangerouslySetInnerHTML={{ __html: faq.answer }}
                      ></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                )
              })}
            </Accordion>
          </Col>
        </Row>
        <Row className="title mb-3 pt-5">
          <Col>
            <h1>Ainda tem dúvidas?</h1>
          </Col>
        </Row>
        <Row className="text-center">
          <Col
            data-sal="slide-left"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <Button onClick={() => navigate("/contato")}>
              Entre em contato
            </Button>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default IndexPage
